/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBasePagingRes, IBaseResponse } from 'src/types';
import http from 'src/utils/http';

export default class BaseService<T, Q = {}, C = {}, U = {}> {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  public async list(query: Q) {
    const { data } = await http.get<IBasePagingRes<T>>(`${this.url}`, { params: query });
    return data;
  }

  public async detail(id: number) {
    const { data } = await http.get<IBaseResponse<T>>(`${this.url}/${id}`);
    return data.data;
  }

  public async create(obj: C) {
    const { data } = await http.post<IBaseResponse<T>>(this.url, obj);
    return data.data;
  }

  public async update(id: number, obj: U) {
    const { data } = await http.put<IBaseResponse<T>>(`${this.url}/${id}`, obj);
    return data.data;
  }

  public async detele(id: number) {
    const { data } = await http.delete<IBaseResponse<T>>(`${this.url}/${id}`);
    return data.data;
  }
}
