import dayjs from 'dayjs';
import { IBaseEntity, IParams } from './common.type';
import { IDebt } from './debt.type';
import { IPack, IPackBody } from './pack.type';
import { EPaymentMethod, EPaymentStatus } from './payment.type';
import { IPaymentDetail } from './payment_detail.type';
import { IProducer } from './producer.type';
import { IProduction } from './production.type';
import { IStorehouse } from './storehouse.type';
import { ITurnback } from './turnback.type';

export interface ISession extends IBaseEntity {
  code: string;
  production_id: number;
  fee: number;
  pay: EPaymentMethod;
  pay_money: number;
  pay_fee: number;
  pay_restored: number;
  pay_status: EPaymentStatus;
  default: number;
  production: IProduction;
  packs: IPack[];
  payment_details: IPaymentDetail[];
  debts: IDebt[];
  storehouse_id: number;
  storehouse: IStorehouse;
  status: ESessionStatus;
  cancelAt: string;
  producer_id?: number;
  producer?: IProducer;
  turnbacks: ITurnback[];
}

export interface ISessionQuery extends IParams {
  code?: string;
  serial?: string;
  dateRange?: dayjs.Dayjs[];
  start?: string;
  end?: string;
  status?: ESessionStatus;
  productVariationIds?: number[];
  storehouseIds?: number[];
  producerIds?: number[];
  payStatus?: EPaymentStatus[];
  restoreStatus?: ESessionRestoreStatus[];
  paymentRestoreStatus?: ESessionRestoreStatus[];
}

export interface ISessionBody {
  storehouse_id: number;
  producer_id: number;
  production_id?: number;
  packs: IPackBody[];
  fee: number;
  default?: number;
  pay?: EPaymentMethod;
}

export interface ICreateSessionBody extends ISessionBody {}
export interface IUpdateSessionBody extends ISessionBody {}

export enum ESessionStatus {
  TRADING = 'TRADING',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
}

export enum ESessionRestoreStatus {
  UNRESTORE = 'UNRESTORE',
  PARTIAL = 'PARTIAL',
  ALL = 'ALL',
}

export enum ESessionRestorePaymentStatus {
  UNPAID = 'UNPAID',
  PARTIAL = 'PARTIAL',
  ALL = 'ALL',
}

export interface ISessionPayment {
  pay_money: number;
  method: EPaymentMethod;
}
