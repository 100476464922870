import { EGenderType, IBaseEntity, IParams } from './common.type';
import { IReceivedAddress } from './received_address.type';

export interface IUser extends IBaseEntity {
  code: string;
  fb_id: string;
  go_id: string;
  code_tax: string;
  code_raw: string;
  name: string;
  name_nr: string;
  email: string;
  password: string;
  point: number;
  spent: number;
  birthday: Date;
  gender: string;
  phone: string;
  province: string;
  district: string;
  address: string;
  status: string;
  type: EUserType;
  is_delete: number;
  addess: IReceivedAddress;
  // orders?: Order[];
  // checkouts?: Checkout[];
  // debts?: Debt[];
  // fcmTokens?: FcmTokens[];
  // introducers?: Introduction[];
  // userSizes?: UserSize[];
  // introduction?: Introduction;
  // emailOtp?: EmailOtp;
  // conversation?: Conversation;
  receivedAddress?: IReceivedAddress[];
  children: IUser[];
  parent: IUser;
}

export interface IUserQuery extends IParams {}

export interface IUserBody {
  name: string;
  code_tax?: string;
  name_nr?: string;
  password?: string;
  email?: string;
  gender?: EGenderType;
  birthday?: string;
  phone?: string;
  province?: string;
  district?: string;
  address?: string;
  type: EUserType;
  fb_id?: string;
  fb_token?: string;
  go_token?: string;
}

export interface ICreateUserBody extends IUserBody {}
export interface IUpdateUserBody extends IUserBody {}

export enum EUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

export enum EUserType {
  RETAIL = 'RETAIL',
  BULK = 'BULK',
  CTV = 'CTV',
}

export enum ERankType {
  GOLD = 'GOLD',
  BRONZE = 'BRONZE',
  SILIVER = 'SILIVER',
}
