import Axios from 'axios';
import { EResponseStatus } from 'src/types';
import { merror } from './message';

// const isDEV = process.env.REACT_APP_ENVIRONMENT === 'development';
const REQ_TIMEOUT = 30 * 1000;

const http = Axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  headers: {
    'Content-Type': 'application/json',
    'type-app': 'CMS',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: REQ_TIMEOUT,
  // withCredentials: true,
  maxBodyLength: Infinity,
});
http.interceptors.request.use((request) => {
  if (request.headers) {
    request.headers.Authorization = `Bearer ${localStorage.getItem('accesstoken') || ''}`;
    // request.headers.refreshtoken = localStorage.getItem('refreshtoken') || '';
  }
  return request;
});

/**
 * intercepts any response and checks the response from our api for a 401 status in the response. ie. the token has now expired and is no longer valid, or no valid token was sent.
 * If such a status exists then we log out the user and clear the profile from redux state.
 */
http.interceptors.response.use(
  (res) => {
    if (res.data?.status !== EResponseStatus.SUCCESS) {
      if (res.data?.status === 'INVALID_TOKEN') {
        merror({ message: 'Phiên đăng nhập hết hạn', status: 401 });
      } else {
        merror({ message: res.data?.message || 'Lỗi hệ thống!', status: 400 });
      }
    }
    return res;
  },
  (err) => {
    merror(err);
    return Promise.reject(err);
  },
);

export default http;
