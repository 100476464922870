import { memo, useEffect, useState } from 'react';
import SpinnerLoader from 'src/libraries/spinner/SpinnerLoader';
import { IBaseProps } from 'src/types';

function InitAppProvider({ children }: IBaseProps) {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchInit();
  }, []);

  const fetchInit = () => {
    setLoading(true);
    setLoading(false);
  };

  if (loading) return <SpinnerLoader bg={false} />;

  return children;
}

export default memo(InitAppProvider);
