import { IBaseEntity, IParams } from './common.type';
import { IProducer } from './producer.type';
import { IProductPlan, IProductPlanBody } from './product_plan.type';
import { ISession } from './session.type';

export interface IProduction extends IBaseEntity {
  code: string;
  producer_id: number;
  producer: IProducer;
  productPlans: IProductPlan[];
  sessions: ISession[];
  status: EProductionStatus;
  is_cancel: number;
}

export interface IProductionQuery extends IParams {
  code?: string;
  status?: EProductionStatus;
  producer_id?: number;
  storehouse_id?: number;
}

export interface IProductionBody {
  code?: string;
  producer_id: number;
  productPlans?: IProductPlanBody[];
}

export interface ICreateProductionBody extends IProductionBody {}
export interface IUpdateProductionBody extends IProductionBody {}

export enum EProductionStatus {
  UNIMPORT = 'UNIMPORT', // chưa nhập
  IMPORT_PARTIAL = 'IMPORT_PARTIAL', // nhập một phần
  FINISHED = 'FINISHED', // hoàn thành
  CANCEL = 'CANCEL', // đã huỷ
}
