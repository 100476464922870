import BaseService from 'src/services/base.service';
import {
  IAdmin,
  IAdminQuery,
  IAuth,
  IBaseResponse,
  ICreateAdminBody,
  ILoginBody,
  IUpdateAdminBody,
  IUpdatePasswordBody,
} from 'src/types';
import http from 'src/utils/http';

const url = '/admin';

export default class AdminService extends BaseService<IAdmin, IAdminQuery, ICreateAdminBody, IUpdateAdminBody> {
  constructor() {
    super(url);
  }

  public async logIn({ phone, password }: ILoginBody) {
    const { data } = await http.post<IAuth>(`${url}/login`, { phone, password });

    return data;
  }

  public async updateCheckoutSelection(id: number, body: { checkout: string }) {
    const { data } = await http.put<IBaseResponse<IAdmin>>(`${url}/checkout/${id}`, body);
    return data.data;
  }

  public async checkEmail(body: { email: string }) {
    const { data } = await http.post<IBaseResponse<any>>(`${url}/check-email`, body);
    return data.data;
  }

  public async changePassword(email: string, body: IUpdatePasswordBody) {
    const { data } = await http.post<IBaseResponse<boolean>>(`${url}/change-password/${email}`, body);
    return data.data;
  }

  public async checkPhoneValidate(body: { phone: string; userId: number }) {
    const { data } = await http.post<IBaseResponse<any>>(`${url}/check-phone-validate`, body);
    return data.data;
  }

  public async checkEmailValidate(body: { email: string; userId: number }) {
    const { data } = await http.post<IBaseResponse<any>>(`${url}/check-email-validate`, body);
    return data.data;
  }

  public async block(id: number) {
    const { data } = await http.post<IBaseResponse<boolean>>(`${url}/inactive/${id}`);
    return data.data;
  }

  public async active(id: number) {
    const { data } = await http.post<IBaseResponse<boolean>>(`${url}/active/${id}`);
    return data.data;
  }

  public async resetPassword(id: number) {
    const { data } = await http.post<IBaseResponse<boolean>>(`${url}/resetPassword/${id}`);
    return data.data;
  }

  public async getProfile() {
    const { data } = await http.get<IBaseResponse<IAdmin>>(`${url}/profile`);
    return data.data;
  }

  // public async logOut() {
  //   await http.post(urlAuth.logout);
  // }

  // public async checkSession() {
  //   try {
  //     const { data } = await http.get(`${url}/checkSession`);
  //     return data;
  //   } catch (err) {
  //     throw err?.response?.data || err;
  //   }
  // }

  // public async getProfile() {
  //   try {
  //     const { data } = await http.get(`${url}/profile`);
  //     return data;
  //   } catch (err) {
  //     throw err?.response?.data || err;
  //   }
  // }
}
