import { IParams } from './common.type';
import { EUserType } from './user.type';

export interface IStorehouseBook {
  product_variation_code?: string;
  storehouse_name?: string;
  storehouse_id?: number;
  product_name?: string;
  product_code?: string;
  product_price?: number;
  product_color?: string;
  product_size?: string;
  product_variation_quantity_received?: number;
  product_variation_quantity_sent?: number;
  product_variation_total_value_after?: number;
  product_variation_total_value_received?: number;
  product_variation_total_value_sent?: number;
  product_variation_quantity_after?: number;
  time?: string;
  category?: EStorehouseBookCategory;
  code?: string;
}

export interface IStorehouseBookQuery extends IParams {
  product_code?: string;
  product_name?: string;
  product_variation_id?: number;
  product_variation_code?: string;
  storehouse_name?: string;
  storehouse_id?: string;
  start?: string;
  end?: string;
}
export interface IStorehouseBookBody {
  product_variation_code?: boolean;
  storehouse_name?: boolean;
  storehouse_id?: boolean;
  product_name?: boolean;
  product_code?: boolean;
  product_price?: boolean;
  product_color?: boolean;
  product_size?: boolean;
  import?: boolean;
  export?: boolean;
  time?: boolean;
  unsold?: boolean;
  category?: boolean;
  code?: boolean;
}

export enum EStorehouseBookCategory {
  IMPORT = 'IMPORT',
  SHIPPING = 'SHIPPING',
  RESTORED = 'RESTORED',
  TURNBACK = 'TURNBACK',
  MOVE_OUT = 'MOVE_OUT',
  MOVE_IN = 'MOVE_IN',
}

// bán hàng tổng
export interface ICheckoutReport {
  checkout_createdAt: string;
  checkout_shipping_createdAt: string;
  user_name: string;
  user_email: string;
  user_phone: string;
  user_code: string;
  user_type: string;
  product_category: string;
  product_name: string;
  product_variation_code: string;
  product_color: string;
  product_size: string;
  product_price: number;
  order_cost: number;
  order_price_discount: number;
  order_tax: number;
  checkout_status: string;
  checkout_pay_status: string;
  checkout_code: string;
  received_address_province: string;
  received_address_district: string;
  storehouse_name: string;
  admin_name: string;
  admin_email: string;
  order_quantity: number;
  order_return: number;
  order_take: number;
  checkout_quantity: number;
  user_quantity: number;
  order_total: number;
  order_return_total: number;
  discount_total: number;
  tax_total: number;
  ship_fee: number;
  income: number;
  income_raw: number;
  cost_total: number;
  earn: number;
  earn_ratio: number;
  ship_real_fee: number;
  point: number;
  earn_real: number;
  order_discount: number;
  order_discount_coupon: number;
  order_price_without_tax: number;
  time: string;
  order_price: number;
  sold_type: string;
  sold_type_detail: string;
  checkout_source: string;
}

export interface ICheckoutReportQuery extends IParams {
  product_code?: string;
  product_name?: string;
  product_variation_id?: number;
  product_variation_code?: string;
  storehouse_name?: string;
  storehouse_id?: string;
  start?: string;
  end?: string;
  time?: string;
}
export interface ICheckoutReportBody {
  checkout_createdAt?: boolean;
  checkout_shipping_createdAt?: boolean;
  user_name?: boolean;
  user_email?: boolean;
  user_phone?: boolean;
  user_code?: boolean;
  user_type?: boolean;
  product_category?: boolean;
  product_name?: boolean;
  product_variation_code?: boolean;
  product_color?: boolean;
  product_size?: boolean;
  product_price?: boolean;
  order_cost?: boolean;
  order_price_discount?: boolean;
  order_tax?: boolean;
  checkout_status?: boolean;
  checkout_pay_status?: boolean;
  checkout_code?: boolean;
  received_address_province?: boolean;
  received_address_district?: boolean;
  storehouse_name?: boolean;
  admin_name?: boolean;
  admin_email?: boolean;
  order_quantity?: boolean;
  order_return?: boolean;
  order_take?: boolean;
  checkout_quantity?: boolean;
  user_quantity?: boolean;
  order_total?: boolean;
  order_return_total?: boolean;
  discount_total?: boolean;
  tax_total?: boolean;
  ship_fee?: boolean;
  income?: boolean;
  income_raw?: boolean;
  cost_total?: boolean;
  earn?: boolean;
  earn_ratio?: boolean;
  ship_real_fee?: boolean;
  point?: boolean;
  earn_real?: boolean;
  order_discount?: boolean;
  order_discount_coupon?: boolean;
  order_price_without_tax?: boolean;
  time?: boolean;
  order_price?: boolean;
  sold_type?: boolean;
  sold_type_detail?: boolean;
  checkout_source?: boolean;
}

// Đơn hàng
export interface ICheckoutReportDetail {
  checkout_code: string;
  checkout_createdAt: string;
  storehouse_name: string;
  checkout_completeAt: string;
  checkout_cancelAt: string;
  checkout_status: string;
  checkout_reason: string;
  checkout_pay_status: string;
  checkout_restore_status: string;
  user_code: string;
  user_name: string;
  user_phone: string;
  user_email: string;
  admin_name: string;
  user_type: string;
  product_code: string;
  product_name: string;
  product_category: string;
  order_quantity: number;
  product_entity_serial: string;
  pack_made: string;
  pack_expire: string;
  order_price: number;
  order_discount: number;
  order_tax: number;
  order_discount_coupon: number;
  checkout_ship_fee: number;
  checkout_total: number;
  checkout_pay_money: number;
  checkout_note: string;
  discount_campaign_name: string;
  coupon_name: string;
  checkout_code_ship: string;
  checkout_selectedAt: string;
  admin_pack_name: string;
  checkout_shippingAt: string;
  admin_ship_name: string;
  checkout_ship_status: string;
  checkout_ship: string;
  received_address_address: string;
  received_address_ward: string;
  received_address_district: string;
  received_address_province: string;
  checkout_cod_money: number;
  checkout_ship_real_fee: number;
  checkout_paidAt: string;
  admin_pay_name: string;
  checkout_pay: string;
  checkout_restoredAt: string;
  checkout_code_restore: string;
  checkout_code_restore_money: number;
  admin_restore_money_name: string;
  order_price_discount_coupon: number;
  order_take: number;
  order_cost: number;
  checkout_restore_total: number;
  order_cost_total: number;
  order_price_without_tax: number;
  pay_status: string;
  discount_total: number;
  order_discount_percent: number;
  price_discount_total: number;
  tax_total: number;
  checkout_discount_percent: number;
  checkout_discount: number;
  order_discount_coupon_percent: number;
  checkout_discount_coupon: number;
  checkout_discount_coupon_percent: number;
  checkout_pay_remain: number;
  order_restore: number;
  restore_money: number;
  product_entity_serial_restore: string;
  order_restore_price: number;
  order_total: number;
  checkout_source: string;
  order_tax_one: number;
  checkout_restoringAt: string;
  discount_total_shipping: number;
  price_discount_total_shipping: number;
  restore_status: string;
}

export interface ICheckoutReportDetailQuery extends IParams {
  start?: string;
  end?: string;
  product_code?: string;
  product_name?: string;
  storehouse_name?: string;
  checkout_code?: string;
  user_name?: string;
  user_phone?: string;
  user_email?: string;
  user_type?: EUserType;
  received_address_province?: string;
  received_address_district?: string;
}
export interface ICheckoutReportDetailBody {
  checkout_code?: boolean;
  checkout_createdAt?: boolean;
  storehouse_name?: boolean;
  checkout_completeAt?: boolean;
  checkout_cancelAt?: boolean;
  checkout_status?: boolean;
  checkout_reason?: boolean;
  checkout_pay_status?: boolean;
  checkout_restore_status?: boolean;
  user_code?: boolean;
  user_name?: boolean;
  user_phone?: boolean;
  user_email?: boolean;
  admin_name?: boolean;
  user_type?: boolean;
  product_code?: boolean;
  product_name?: boolean;
  product_category?: boolean;
  order_quantity?: boolean;
  product_entity_serial?: boolean;
  pack_made?: boolean;
  pack_expire?: boolean;
  order_price?: boolean;
  order_discount?: boolean;
  order_tax?: boolean;
  order_discount_coupon?: boolean;
  checkout_ship_fee?: boolean;
  checkout_total?: boolean;
  checkout_pay_money?: boolean;
  checkout_note?: boolean;
  discount_campaign_name?: boolean;
  coupon_name?: boolean;
  checkout_code_ship?: boolean;
  checkout_selectedAt?: boolean;
  admin_pack_name?: boolean;
  checkout_shippingAt?: boolean;
  admin_ship_name?: boolean;
  checkout_ship_status?: boolean;
  checkout_ship?: boolean;
  received_address_address?: boolean;
  received_address_ward?: boolean;
  received_address_district?: boolean;
  received_address_province?: boolean;
  checkout_cod_money?: boolean;
  checkout_ship_real_fee?: boolean;
  checkout_paidAt?: boolean;
  admin_pay_name?: boolean;
  checkout_pay?: boolean;
  checkout_restoredAt?: boolean;
  checkout_code_restore?: boolean;
  checkout_code_restore_money?: boolean;
  admin_restore_money_name?: boolean;
  order_price_discount_coupon?: boolean;
  order_take?: boolean;
  order_cost?: boolean;
  checkout_restore_total?: boolean;
  order_cost_total?: boolean;
  order_price_without_tax?: boolean;
  pay_status?: boolean;
  discount_total?: boolean;
  order_discount_percent?: boolean;
  price_discount_total?: boolean;
  tax_total?: boolean;
  checkout_discount_percent?: boolean;
  checkout_discount?: boolean;
  order_discount_coupon_percent?: boolean;
  checkout_discount_coupon?: boolean;
  checkout_discount_coupon_percent?: boolean;
  checkout_pay_remain?: boolean;
  order_restore?: boolean;
  product_entity_serial_restore?: boolean;
  restore_money?: boolean;
  order_restore_price?: boolean;
  order_total?: boolean;
  checkout_source?: boolean;
  order_tax_one?: boolean;
  checkout_restoringAt?: boolean;
}

// Nhập kho
export interface IImport {
  production_code: string;
  session_code: string;
  production_createdAt: string;
  session_createdAt: string;
  product_variation_quantity: number;
  product_variation_cost: number;
  fee: number;
  pack_tax: number;
  tax_total: number;
  price_with_tax_fee: number;
  product_variation_code: string;
  product_name: string;
  product_plan_price: number;
  price_with_tax: number;
  product_entity_serial: string;
  pack_made: string;
  pack_expire: string;
  turnback_code: string;
  turnback_createdAt: string;
  turnback_admin_id: number;
  product_variation_quantity_turnback: number;
  total: number;
}

export interface IImportQuery extends IParams {
  producer_name?: string;
  product_code?: string;
  product_name?: string;
  product_variation_code?: string;
  start?: string;
  end?: string;
}
export interface IImportBody {
  production_code?: boolean;
  session_code?: boolean;
  production_createdAt?: boolean;
  session_createdAt?: boolean;
  product_variation_quantity?: boolean;
  product_variation_cost?: boolean;
  fee?: boolean;
  pack_tax?: boolean;
  tax_total?: boolean;
  price_with_tax_fee?: boolean;
  product_variation_code?: boolean;
  product_name?: boolean;
  product_plan_price?: boolean;
  price_with_tax?: boolean;
  product_entity_serial?: boolean;
  pack_made?: boolean;
  pack_expire?: boolean;
  turnback_code?: boolean;
  turnback_createdAt?: boolean;
  turnback_admin_id?: boolean;
  product_variation_quantity_turnback?: boolean;
  total?: boolean;
}

// Tồn kho
export interface IUnsold {
  PRODUCT_NAME: string;
  PRODUCT_COLOR: string;
  PRODUCT_SIZE: string;
  PRODUCT_VARIATION_CODE: string;
  PRODUCT_CATEGORY: string;
  UNSOLD_ALL: number;
  COST: number;
  cost_total_all: number;
  UNSOLD: number;
  SELLABLE: number;
  TRADING: number;
  RESTORING: number;
  SHIPPING: number;
  COST_TOTAL: number;
  RATIO: number;
}

export interface IUnsoldQuery extends IParams {
  product_code?: string;
  product_name?: string;
  storehouse_name?: string;
  start?: string;
  end?: string;
}
export interface IUnsoldBody {
  product_name?: boolean;
  product_color?: boolean;
  product_size?: boolean;
  product_variation_code?: boolean;
  product_category?: boolean;
  unsold_all?: boolean;
  cost?: boolean;
  cost_total_all?: boolean;
  unsold?: boolean;
  sellable?: boolean;
  trading?: boolean;
  restoring?: boolean;
  shipping?: boolean;
  cost_total?: boolean;
  ratio?: boolean;
}
