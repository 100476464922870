import { IBaseConfirmModalParams } from 'src/types';
import EventBus from './event-bus';
import { EventBusName, IBaseEventPayload } from './event-bus.types';

export const onShowModalConfirm = (params: IBaseConfirmModalParams) => {
  EventBus.getInstance().post<IBaseEventPayload<any>>({
    type: EventBusName.SHOW_MODAL_CONFIRM,
    payload: params,
  });
};

export const onRedirect = (path: string) => {
  EventBus.getInstance().post<IBaseEventPayload<any>>({
    type: EventBusName.REDIRECT,
    payload: path,
  });
};
