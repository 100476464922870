import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { ILayout } from 'src/types';
import { setLayout } from './layout.slice';

export default function useLayout() {
  const dispatch = useDispatch();
  const layout = useAppSelector((state) => state.layout);

  const changeLayout = (data: ILayout) => {
    dispatch(setLayout(data));
  };

  return {
    layout,
    changeLayout,
  };
}
