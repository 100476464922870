import dayjs from 'dayjs';
import { IBaseEntity, IParams } from './common.type';
import { EPaymentMethod } from './payment.type';
import { IProductVariation } from './productVariation.type';
import { IProductEntity } from './product_entity.type';

export interface IMove extends IBaseEntity {
  code: string;
  code_ref: string;
  note: string;
  ship_price: number;
  status: EMoveStatus;
  from_id: number;
  from_name: string;
  to_id: number;
  to_name: string;
  product_variations: { product_entities: IProductEntity[]; productVariation: IProductVariation }[];
  quantity: number;
  confirmAt: string;
  receivedAt: string;
}

export interface IMoveQuery extends IParams {
  dateRange?: dayjs.Dayjs[];
  from_id?: number;
  to_id?: number;
  code?: string;
  code_ref?: string;
  status?: string;
  product_variation_id?: number;
  start?: string;
  end?: string;
  confirm_start?: string;
  confirm_end?: string;
  received_start?: string;
  received_end?: string;
}

export interface IMoveBody {
  from_id: number;
  to_id: number;
  code?: string;
  code_ref?: string;
  note?: string;
  ship_price?: number;
  productEntities?: number[];
}

export interface ICreateMoveBody extends IMoveBody {}
export interface IUpdateMoveBody extends IMoveBody {}

export enum EMoveStatus {
  PENDING = 'PENDING',
  CONFIRM = 'CONFIRM',
  RECEIVED = 'RECEIVED',
  CANCEL = 'CANCEL',
}

export interface IMovePayment {
  pay_money: number;
  method: EPaymentMethod;
}

export interface IReceivedMoveBody {
  ids?: number[];
}

export interface ICreateDistributionMoveBody {
  default: number;
  stack_id?: number;
  move_id: number;
  productEntities?: { id: number }[];
}
