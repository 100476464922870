import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'src/routes/route';
import { useAppSelector } from 'src/stores';
import { IAuth } from 'src/types';
import { setAccount } from './auth.slice';

export default function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);

  const login = (data: IAuth) => {
    dispatch(setAccount(data.user));
    localStorage.setItem('accesstoken', data.token);
    localStorage.setItem('refreshtoken', data.refreshToken);
  };

  const logout = () => {
    dispatch(setAccount(undefined));
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('refreshtoken');
    navigate(Routes.LOGIN.path);
  };

  return {
    user,
    login,
    logout,
  };
}
