import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Page403 from 'src/screens/errors/page403';
import AdminService from 'src/services/admin.service';
import { setAccount } from 'src/stores/auth/auth.slice';
import useAuth from 'src/stores/auth/auth.store';
import { IBaseProps } from 'src/types';

interface IProps extends IBaseProps {}

function PrivateRoute({ children }: IProps) {
  const { user, logout } = useAuth();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    checkSession();
  }, []);

  const permissionDeny = useMemo<boolean>(() => {
    if (!user) return true;
    // Check permission

    return false;
  }, [pathname, user]);

  const checkSession = async () => {
    try {
      const adminSv = new AdminService();
      const result = await adminSv.getProfile();
      if (!result) logout();
      else dispatch(setAccount(result));
    } catch (error) {
      logout();
    }
    if (!user) {
      logout();
    }
  };

  if (permissionDeny) {
    return <Page403 />;
  }

  return children;
}

export default React.memo(PrivateRoute);
