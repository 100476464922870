/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface IProps {
  bg: boolean;
  className?: string;
}

function SpinnerLoader({ bg, className }: IProps) {
  return (
    <div
      id="preloader"
      className={className}
      style={bg ? { backgroundColor: '#e3e3e3', opacity: 0.5 } : { backgroundColor: 'transparent' }}
    >
      <div className="waviy">
        <span style={{ '--i': '1' } as any}>L</span>
        <span style={{ '--i': '2' } as any}>O</span>
        <span style={{ '--i': '3' } as any}>A</span>
        <span style={{ '--i': '4' } as any}>D</span>
        <span style={{ '--i': '5' } as any}>I</span>
        <span style={{ '--i': '6' } as any}>N</span>
        <span style={{ '--i': '7' } as any}>G</span>
        <span style={{ '--i': '8' } as any}>.</span>
        <span style={{ '--i': '9' } as any}>.</span>
        <span style={{ '--i': '10' } as any}>.</span>
      </div>
    </div>
  );
}

SpinnerLoader.defaultProps = {
  className: undefined,
};

export default React.memo(SpinnerLoader);
