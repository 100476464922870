import { IBaseEntity } from './common.type';
import { IDistribution } from './distribution.type';
import { IOrderStore } from './order_store.type';
import { IPack } from './pack.type';
import { IStorehouse } from './storehouse.type';

export interface IProductEntity extends IBaseEntity {
  serial: string;
  cost: number;
  tax: number;
  fee: number;
  pack_id: number;
  pack: IPack;
  distribution_id: number;
  distribution: IDistribution;
  turnback_distribution_id: number;
  // turnbackDistribution: ITurnbackDistribution;
  orderStores: IOrderStore[];
  // orderRestores: IOrderRestore[];
  status: EProductEntityStatus;
  // moves: IMove[];
  storehouse_id: number;
  storehouse: IStorehouse;
  // productEntityHistories: IProductEntityHistory[];
}

export enum EProductEntityStatus {
  CREATED = 'CREATED',
  STORED = 'STORED',
  TURNBACK = 'TURNBACK',
  PENDING = 'PENDING',
  INVALID = 'INVALID',
  PACKED = 'PACKED',
  HAND_OVER = 'HAND_OVER',
  SHIPPING = 'SHIPPING',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  COMPLETE = 'COMPLETE',
  SHIPPED = 'SHIPPED',
  MOVED = 'MOVED',
  MOVING = 'MOVING',
}

export interface IProductEntityBody {}

export interface ICreateProductEntityBody {}
export interface IUpdateProductEntityBody {
  ids: string;
  status: string;
  order_restore_id?: number;
}
