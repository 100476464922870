import { memo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Subscription } from 'rxjs';
import useMessageStore from 'src/stores/message';
import { IBaseProps } from 'src/types';
import { EventBusName } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';

interface IProps extends IBaseProps {}

function MessageProvider(props: IProps) {
  useMessageStore();
  const navigate = useNavigate();

  const subscriptions = useRef<any>();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: any) => {
        switch (data.type) {
          case EventBusName.REDIRECT:
            navigate(data.payload);
            break;
          default:
            break;
        }
      }),
    );
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  return props.children;
}

export default memo(MessageProvider);
