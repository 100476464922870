import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdmin } from 'src/types';
import { IAuthState } from './auth.state';

const initialState: IAuthState = Object.freeze({
  user: undefined,
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, { payload }: PayloadAction<IAdmin | undefined>) => ({
      ...state,
      user: payload,
    }),
  },
});

export const { setAccount } = authSlice.actions;

export default authSlice.reducer;
