import { IAdmin } from './admin.type';
import { ICheckout } from './checkout.type';
import { IBaseEntity } from './common.type';

export interface IPrint extends IBaseEntity {
  checkout_id: number;
  checkout: ICheckout;
  admin_id: number;
  admin: IAdmin;
  type: EPrintType;
}

export enum EPrintType {
  SELL = 'SELL',
}
