import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILayout, LayoutColor, LayoutType } from 'src/types';

const initialState: ILayout = {
  type: LayoutType.VERTICAL,
  primaryColor: '#b61e42',
  bgColor: LayoutColor.DARK,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLayout: (state, { payload }: PayloadAction<ILayout | undefined>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
