import { IBaseEntity, IParams } from './common.type';
import { IProduct } from './product.type';

export interface IPrice extends IBaseEntity {
  product_id: number;
  product: IProduct;
  value: number;
  type: EPriceType;
  min_quantity: number;
  temp_id?: string;
}

export interface IPriceQuery extends IParams {
  product_id?: number;
  type?: EPriceType;
  min_quantity?: number;
}

export interface IPriceBody {}

export interface ICreatePriceBody extends IPriceBody {
  value: number;
  type: EPriceType;
  min_quantity: number;
  temp_id?: string;
}
export interface IUpdatePriceBody extends IPriceBody {
  value?: number;
  min_quantity?: number;
}

export enum EPriceType {
  BULK = 'BULK',
  CTV = 'CTV',
}
