import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'src/routes/route';

function Error403() {
  const navigate = useNavigate();
  const goHome = () => {
    navigate(Routes.HOME.path);
  };
  return (
    <Result
      status="403"
      title="403"
      subTitle="Xin lỗi, bạn không được cấp quyền truy cập nội dung này."
      extra={
        <Button onClick={goHome} type="primary">
          Trang chủ
        </Button>
      }
    />
  );
}

export default Error403;
