import { EGenderType, IBaseEntity, IParams } from './common.type';
import { IRole } from './role.type';
import { IStorehouse } from './storehouse.type';

export interface IAdmin extends IBaseEntity {
  name: string;
  email: string;
  email_work: string;
  password: string;
  phone: string;
  phone_work: string;
  checkout: string;
  role: string;
  birthday: string;
  gender: string;
  code: string;
  address: string;
  status: EAdminStatus;
  note: string;
  code_secure: string;
  is_delete: number;

  // TODO: thêm type sau
  roles: IRole[];
  // checkouts: Checkout[];
  // prints: Print[];
  // payments: Payment[];
  // message: Message;
  storehouses: IStorehouse[];
}

export interface ILoginBody {
  phone: string;
  password: string;
}

export interface IAdminQuery extends IParams {
  role_id?: number;
  status?: EAdminStatus;
}

export interface IAdminBody {
  phone: string;
  name: string;
  birthday?: string;
  gender?: EGenderType;
  email: string;
  address?: string;
  roles: IRole[];
  note?: string;
  email_work?: string;
  phone_work?: string;
  storehouse_ids?: IStorehouse[];
}

export interface ICreateAdminBody extends IAdminBody {}
export interface IUpdateAdminBody extends IAdminBody {}

export interface IUpdatePasswordBody {
  newpassword: string;
  confirmPassword: string;
}

export enum EAdminStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
