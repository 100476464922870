import { IAdmin } from './admin.type';
import { ICheckout } from './checkout.type';
import { IBaseEntity, IParams } from './common.type';
import { IPayment } from './payment.type';
import { IProducer } from './producer.type';
import { ISession } from './session.type';
import { EUserType, IUser } from './user.type';

export interface IDebt extends IBaseEntity {
  type: EDebtType;
  code: string;
  obj: EDebtObj;
  payment_id: number;
  payment: IPayment;
  user_id: number;
  user: IUser;
  producer_id: number;
  producer: IProducer;
  admin_id: number;
  admin: IAdmin;
  checkout_id: number;
  checkout: ICheckout;
  session_id: number;
  session: ISession;
  price: number;
  price_asc: number;
  price_desc: number;
  prev_price: number;
  note: string;
  description: string;
  status: EDebtStatus;
  approvedAt: string;
}

interface IDebtTotal {
  debt_first_stage: number;
  debt_asc_stage: number;
  debt_desc_stage: number;
  debt_stage: number;
  debt_last_stage: number;
}

export interface IDebtCustomer extends IDebtTotal {
  user_id: number;
  user_name: string;
  user_phone: string;
  user_email: string;
  user_type: EUserType;
}

export interface IDebtProducer extends IDebtTotal {
  producer_id: number;
  producer_name: string;
  producer_phone: string;
  producer_email: string;
}

export interface IDebtQuery extends IParams {
  type?: EDebtType;
  start?: string;
  end?: string;
  q?: string;
  obj_id?: number;
}

export interface IDebtBody {
  code: string;
  type: EDebtType;
  obj: EDebtObj;
  price?: number;
  note?: string;
  description?: string;
  approvedAt?: string;
  status?: EDebtStatus;
  payment_id?: number;
  checkout_id?: number;
  session_id?: number;
  user_id?: number;
  producer_id?: number;
}

export interface ICreateDebtBody extends IDebtBody {}
export interface IUpdateDebtBody extends IDebtBody {}

export enum EDebtObj {
  CUSTOMER = 'CUSTOMER', // khách hàng
  PRODUCER = 'PRODUCER', // nhà cung cấp
}

export enum EDebtType {
  RECEIVER = 'RECEIVER', // phiếu thu
  DEBT = 'DEBT', // phiếu công nợ
  PAID = 'PAID', // phiếu trả
}

export enum EDebtStatus {}
