import { Routes } from 'src/routes/route';
import { message } from 'src/stores/message';
import { HttpStatusCode } from 'src/types';
import { onRedirect } from './event-bus/event';

// interface IErrorResponse {
//   message: string;
//   status: number;
//   response?: any;
//   error?: string;
// }

export const merror = (err: any) => {
  if (err.status === HttpStatusCode.UNAUTHORIZED) {
    message.error('Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại!');
    // dispatch(logOutAction());
    onRedirect(Routes.PAGE403.path);
    return;
  }

  if (err.status === HttpStatusCode.FORBIDDEN) {
    message.error('Bạn không có quyền truy cập chức năng này!');
    return;
  }

  if (err.status === HttpStatusCode.BAD_REQUEST) {
    message.error(err.message);

    return;
  }

  if (err?.status === HttpStatusCode.ERROR_SERVER) {
    message.error(err.response?.message || 'Lỗi hệ thống, bạn vui lòng liên hệ quản trị viên!');
    return;
  }

  if (err?.status === HttpStatusCode.NOT_FOUND) {
    message.error('Không tìm thấy địa chỉ API!');
    onRedirect(Routes.PAGE404.path);
    return;
  }

  message.error('Quá thời gian chờ, vui lòng thử lại!');
};

export const msuccess = (mess: string) => {
  message.success(mess);
};

export const minfo = (mess: string) => {
  message.info(mess);
};

export const mwarning = (mess: string) => {
  message.warning(mess);
};
