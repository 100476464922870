import dayjs from 'dayjs';
import { IAdmin } from './admin.type';
import { ICheckoutRestore } from './checkout_restore.type';
import { IBaseEntity, IParams } from './common.type';
import { IDebt } from './debt.type';
import { IImage } from './image.type';
import { IOrder, IOrderBody } from './order.type';
import { ICreateOrderStoreBody } from './order_store.type';
import { EPaymentStatus } from './payment.type';
import { IPaymentDetail } from './payment_detail.type';
import { IPrint } from './print.type';
import { IReceivedAddress } from './received_address.type';
import { IStorehouse } from './storehouse.type';
import { IUser } from './user.type';

export interface ICheckout extends IBaseEntity {
  code: string;
  source: string;
  code_ship: string;
  code_restore: string;
  code_restore_money: string;
  code_ref: string;
  ship_code: string;
  ship_cancel_reason: string;
  point: number;
  point_receive: number;
  ship_fee: number;
  ship_real_fee: number;
  pay_fee: number;
  wrap_fee: number;
  ship: string;
  pay: string;
  cod_money: number;
  wrap: string;
  reason: string;
  note: string;
  is_approved: number;
  user_temp_id: number;
  orders: IOrder[];
  checkoutRestores: ICheckoutRestore[];
  user_id: number;
  user: IUser;
  admin_id: number;
  admin: IAdmin;
  admin_id_pack: number;
  admin_pack: IAdmin;
  admin_id_ship: number;
  admin_ship: IAdmin;
  admin_id_pay: number;
  admin_pay: IAdmin;
  admin_id_restore: number;
  admin_restore: IAdmin;
  admin_id_restore_money: number;
  admin_restore_money: IAdmin;
  received_address_id: number;
  receivedAddress: IReceivedAddress;
  status: ECheckoutStatus;
  pay_status: EPaymentStatus;
  pay_money: number;
  ship_status: EShipStatus;
  coupon_history_id: number;
  // couponHistory: CouponHistory;
  introduction_id?: number;
  // introduction: IIntroduction;
  coupon_value: number;
  discount: number;
  discount_value: number;
  total: number;
  total_with_dis_and_cou: number;
  restore_status: ECheckoutRestore;
  restore_total: number;
  restore_total_with_dis_and_cou: number;
  restore_point: number;
  restore_introduction?: number;
  restore_coupon?: number;
  storehouse_id: number;
  storehouse: IStorehouse;
  // pointUseds: PointUsed[];
  prints: IPrint[];
  image_id: number;
  image: IImage;
  images: IImage[];
  payment_details: IPaymentDetail[];
  debts: IDebt[];
  // checkoutHistories: CheckoutHistory[];
  // productEntityHistories: ProductEntityHistory[];
  checkout_status: ENewCheckoutStatus;
  pack_status: EPackCheckoutStatus;
  export_status: EExportCheckoutStatus;
  export_date: Date;
  complete_date: Date;
  cancel_date: Date;
}

export interface ICheckoutQuery extends IParams {
  code?: string;
  storehouse_ids?: number[];
  productVariationId?: number;
  user_ids?: number[];
  ship_name?: string;
  source?: string[];
  export_date?: string;
  complete_date?: string;
  cancel_date?: string;
  printed?: number;
  statuses?: string[];
  status?: string;
  checkout_status?: string[];
  pack_status?: string[];
  export_status?: string[];
  restore_status?: string[];
  pay_status?: string[];
  ship_status?: string[];
  start?: string;
  end?: string;
  total?: number;
  user_temp_id?: number;
  dateRange?: dayjs.Dayjs[];
}

export interface ICheckoutBody {
  code_ref?: string;
  source?: string;
  orders?: IOrderBody[];
  coupon_id?: number;
  point?: number;
  pay_fee?: number;
  wrap_fee?: number;
  ship_real_fee?: number;
  ship?: string;
  ship_fee?: number;
  pay?: string;
  pay_money?: number;
  wrap?: string;
  discount?: number;
  note?: string;
  pay_status?: string;
  received_address_id?: number;
  introduction_id?: number;
  storehouse_id?: number;
}

export interface ICreateCheckoutBody extends ICheckoutBody {}
export interface IUpdateCheckoutBody extends ICheckoutBody {}

export interface ISelectedCheckoutBody {
  storehouse_id: number;
  checkoutOrderStores: ICreateOrderStoreBody[];
}
export interface IHandoverCheckoutBody {
  ship: string;
  ship_code?: string;
  ship_real_fee?: number;
  ship_fee?: number;
  cod_money?: number;
  total_weight?: number;
}

export enum ECheckoutStatus {
  PENDING = 'PENDING',
  CANCEL = 'CANCEL',
  APPROVE = 'APPROVE',
  SELECTED = 'SELECTED',
  PACKED = 'PACKED',
  HAND_OVER = 'HAND_OVER',
  SHIPPING = 'SHIPPING',
  RESTORING = 'RESTORING',
  RESTORING_CANCEL = 'RESTORING_CANCEL',
  RESTORED = 'RESTORED',
  COMPLETE = 'COMPLETE',
}

export enum ENewCheckoutStatus {
  CANCEL = 'CANCEL', // Đã huỷ
  USER_CONFIRM = 'USER_CONFIRM', // Đại lý xác nhận
  PENDING = 'PENDING', // Đợi xác nhận
  TRADING = 'TRADING', // Đang giao dịch
  COMPLETE = 'COMPLETE', // Hoàn thành
}

export enum EPackCheckoutStatus {
  UNPACKED = 'UNPACKED', // Chưa đóng gói
  PACKED = 'PACKED', // Đã đóng gói
}

export enum EExportCheckoutStatus {
  UNEXPORTED = 'UNEXPORTED', // Chưa xuất kho
  EXPORTED = 'EXPORTED', // Đã xuất kho
}

export enum EShipStatus {
  SHIPPING = 'SHIPPING',
  SHIPPED = 'SHIPPED',
  // SHIPPING = 'SHIPPING',
}

export enum ECheckoutRestore {}
