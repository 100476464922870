import { IAdmin } from './admin.type';
import { IBaseEntity, IParams } from './common.type';
import { IPaymentDetail } from './payment_detail.type';
import { IPaymentType } from './payment_type.type';

export interface IPayment extends IBaseEntity {
  object_id: number;
  object_type: EPaymentObjectType;
  code: string;
  admin_id: number;
  admin: IAdmin;
  payment_details: IPaymentDetail[];
  value: number;
  note: string;
  mode: EPaymentMode;
  method: EPaymentMethod;
  payment_type_id: number;
  payment_type: IPaymentType;
}

export interface IPaymentQuery extends IParams {
  mode?: EPaymentMode;
  payment_type_id?: number;
  method?: EPaymentMethod;
  object_type?: EPaymentObjectType;
}

export interface IPaymentBody {
  object_id: number;
  object_type?: EPaymentObjectType;
  admin_id?: number;
  checkout_id?: number;
  session_id?: number;
  move_id?: number;
  value: number;
  note?: string;
  mode?: EPaymentMode;
  payment_type_id?: number;
  auto?: boolean;
  method?: EPaymentMethod;
}

export interface ICreatePaymentBody extends IPaymentBody {}
export interface IUpdatePaymentBody extends IPaymentBody {}

export enum EPaymentMode {
  RECEIVER = 'RECEIVER',
  PAID = 'PAID',
}

export enum EPaymentObjectType {
  CUSTOMER = 'CUSTOMER',
  STAFF = 'STAFF',
  PRODUCER = 'PRODUCER',
}

export enum EPaymentMethod {
  COD = 'COD',
  TRANSFER = 'TRANSFER',
}

export enum EPaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  PAID_PARTIAL = 'PAID_PARTIAL',
  PAID_RESTORED = 'PAID_RESTORED',
}
