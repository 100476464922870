import { IBaseEntity, IParams } from './common.type';
import { IImage } from './image.type';

export interface IBanner extends IBaseEntity {
  name: string;
  content: string;
  show: number;
  type: EBannerType;
  image_mb_id: number;
  image_mb: IImage;
  image_pc_id: number;
  image_pc: IImage;
  link_mb: string;
  link_pc: string;
}

export interface IBannerQuery extends IParams {
  show?: number;
}

interface IBannerBody {
  name: string;
  content: string;
  type: EBannerType;
  image_mb_id?: number;
  image_pc_id?: number;
  link_mb?: string;
  link_pc?: string;
}

export interface ICreateBannerBody extends IBannerBody {}
export interface IUpdateBannerBody extends IBannerBody {}

export enum EBannerType {
  HOME_BANNER = 'HOME_BANNER',
  COUPON_BANNER_PERCENT = 'COUPON_BANNER_PERCENT',
  COUPON_BANNER_CURRENCY = 'COUPON_BANNER_CURRENCY',
}
