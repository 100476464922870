import { App } from 'antd';
import { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import SpinnerLoader from './libraries/spinner/SpinnerLoader';
import ConfigAntdProvider from './providers/ConfigAntdProvider';
import InitAppProvider from './providers/initApp.provider';
import MessageProvider from './providers/message.provider';
import SocketProvider from './providers/socket.provider';
import PrivateRoute from './routes/privateRoute';
import { Routes as Router } from './routes/route';
import { persistor, store } from './stores';
import './styles/custom.scss';

const LoginScreen = lazy(() => import('./screens/login/index'));
const DashboardScreen = lazy(() => import('./screens/dashboard/index'));
const Page404Screen = lazy(() => import('./screens/errors/page404'));
const Page500Screen = lazy(() => import('./screens/errors/page500'));
const Page403Screen = lazy(() => import('./screens/errors/page403'));
const Privacy = lazy(() => import('./screens/privacy/privacy'));
const LayoutClient = lazy(() => import('./layouts'));
// const PageComingSoonScreen = lazy(() => import('./screens/errors/comingSoon'));

// checkout
const CheckoutScreen = lazy(() => import('./screens/checkout/checkout'));
const CreateCheckoutScreen = lazy(() => import('./screens/checkout/checkout/create'));
const EditCheckoutScreen = lazy(() => import('./screens/checkout/checkout/edit'));
const CheckoutRestoreScreen = lazy(() => import('./screens/checkout/restore'));
const CreateCheckoutRestoreScreen = lazy(() => import('./screens/checkout/restore/create'));
const EditCheckoutRestoreScreen = lazy(() => import('./screens/checkout/restore/edit'));

// storehouse
const StorehouseScreen = lazy(() => import('./screens/storehouse/storehouse'));
const CreateStorehouseScreen = lazy(() => import('./screens/storehouse/storehouse/create'));
const EditStorehouseScreen = lazy(() => import('./screens/storehouse/storehouse/edit'));
const ProducerScreen = lazy(() => import('./screens/storehouse/producer'));
const CreateProducerScreen = lazy(() => import('./screens/storehouse/producer/create'));
const EditProducerScreen = lazy(() => import('./screens/storehouse/producer/edit'));
const ProductionScreen = lazy(() => import('./screens/storehouse/production'));
const CreateProductionScreen = lazy(() => import('./screens/storehouse/production/create'));
const EditProductionScreen = lazy(() => import('./screens/storehouse/production/edit'));
const SessionScreen = lazy(() => import('./screens/storehouse/session'));
const CreateSessionScreen = lazy(() => import('./screens/storehouse/session/create'));
const EditSessionScreen = lazy(() => import('./screens/storehouse/session/edit'));
const TurnbackScreen = lazy(() => import('./screens/storehouse/turnback'));
const CreateTurnbackScreen = lazy(() => import('./screens/storehouse/turnback/create'));
const EditTurnbackScreen = lazy(() => import('./screens/storehouse/turnback/edit'));
const RepoScreen = lazy(() => import('./screens/storehouse/storehouse/repo'));
const StackDetailScreen = lazy(() => import('./screens/storehouse/storehouse/stack-detail'));
const MoveScreen = lazy(() => import('./screens/storehouse/move'));
const CreateMoveScreen = lazy(() => import('./screens/storehouse/move/create'));
const EditMoveScreen = lazy(() => import('./screens/storehouse/move/edit'));

// product
const ProductScreen = lazy(() => import('./screens/product/product'));
const CreateProductScreen = lazy(() => import('./screens/product/product/create'));
const EditProductScreen = lazy(() => import('./screens/product/product/edit'));
const CategoryScreen = lazy(() => import('./screens/product/category'));
const ProductSuggestionScreen = lazy(() => import('./screens/product/suggestion'));
const ProductColorScreen = lazy(() => import('./screens/product/product_color'));
const ProductSizeScreen = lazy(() => import('./screens/product/product_size'));
const IconScreen = lazy(() => import('./screens/product/icon'));
const PointRuleScreen = lazy(() => import('./screens/product/product_point_rule'));
const DiscountCampaignScreen = lazy(() => import('./screens/product/discount-campaign'));
const CouponScreen = lazy(() => import('./screens/product/coupon'));
const CollectionScreen = lazy(() => import('./screens/product/collection'));

// account
const AdminScreen = lazy(() => import('./screens/account/admin'));
const CreateAdminScreen = lazy(() => import('./screens/account/admin/create'));
const EditAdminScreen = lazy(() => import('./screens/account/admin/edit'));
const UserScreen = lazy(() => import('./screens/account/user'));
const CreateUserScreen = lazy(() => import('./screens/account/user/create'));
const DetailUserScreen = lazy(() => import('./screens/account/user/detail'));
const PointSignupScreen = lazy(() => import('./screens/account/point-signup'));
const IntroductionRuleScreen = lazy(() => import('./screens/account/introduction-rule'));
const RankScreen = lazy(() => import('./screens/account/rank'));

// Role
const RoleScreen = lazy(() => import('./screens/role'));

// debt
const DebtCustomerScreen = lazy(() => import('./screens/debt/customer'));
const DetailDebtCustomerScreen = lazy(() => import('./screens/debt/customer/detail'));
const DebtProducerScreen = lazy(() => import('./screens/debt/producer'));
const DetailDebtProducerScreen = lazy(() => import('./screens/debt/producer/detail'));
const PaymentScreen = lazy(() => import('./screens/debt/payment'));
const DetailPaymentScreen = lazy(() => import('./screens/debt/payment/detail'));

// report
const StorehouseBookScreen = lazy(() => import('./screens/report/storehouse-book'));
const CheckoutReportScreen = lazy(() => import('./screens/report/checkout-report'));
const CheckoutReportDetailScreen = lazy(() => import('./screens/report/checkout-repport-detail'));
const UnsoldScreen = lazy(() => import('./screens/report/unsold'));
const ImportScreen = lazy(() => import('./screens/report/import'));

// messages
const MessagesScreen = lazy(() => import('./screens/messages/chats'));
// setting
const BannerScreen = lazy(() => import('./screens/setting/banner'));
const FAQScreen = lazy(() => import('./screens/setting/faq'));
const GuideScreen = lazy(() => import('./screens/setting/guide'));

function AppClient() {
  return (
    <Provider store={store}>
      <PersistGate loading={<SpinnerLoader bg={false} />} persistor={persistor}>
        <ConfigAntdProvider>
          <App>
            <MessageProvider>
              <Suspense fallback={<SpinnerLoader bg={false} />}>
                <Routes>
                  <Route
                    element={
                      <PrivateRoute>
                        <InitAppProvider>
                          <SocketProvider>
                            <LayoutClient />
                          </SocketProvider>
                        </InitAppProvider>
                      </PrivateRoute>
                    }
                  >
                    <Route path={Router.HOME.path} element={<DashboardScreen />} />

                    {/* Checkout */}
                    <Route path={Router.CHECKOUT.path} element={<CheckoutScreen />} />
                    <Route path={Router.CREATE_CHECKOUT.path} element={<CreateCheckoutScreen />} />
                    <Route path={`${Router.EDIT_CHECKOUT.path}/:id`} element={<EditCheckoutScreen />} />
                    <Route path={Router.CHECKOUT_RESTORE.path} element={<CheckoutRestoreScreen />} />
                    <Route path={Router.CREATE_CHECKOUT_RESTORE.path} element={<CreateCheckoutRestoreScreen />} />
                    <Route path={`${Router.EDIT_CHECKOUT_RESTORE.path}/:id`} element={<EditCheckoutRestoreScreen />} />

                    {/* Storehouse */}
                    <Route path={Router.STOREHOUSE.path} element={<StorehouseScreen />} />
                    <Route path={Router.CREATE_STOREHOUSE.path} element={<CreateStorehouseScreen />} />
                    <Route path={`${Router.EDIT_STOREHOUSE.path}/:id`} element={<EditStorehouseScreen />} />
                    <Route path={Router.PRODUCER.path} element={<ProducerScreen />} />
                    <Route path={Router.CREATE_PRODUCER.path} element={<CreateProducerScreen />} />
                    <Route path={`${Router.EDIT_PRODUCER.path}/:id`} element={<EditProducerScreen />} />
                    <Route path={Router.PRODUCTION.path} element={<ProductionScreen />} />
                    <Route path={Router.CREATE_PRODUCTION.path} element={<CreateProductionScreen />} />
                    <Route path={`${Router.EDIT_PRODUCTION.path}/:id`} element={<EditProductionScreen />} />
                    <Route path={Router.SESSION.path} element={<SessionScreen />} />
                    <Route path={Router.CREATE_SESSION.path} element={<CreateSessionScreen />} />
                    <Route path={`${Router.EDIT_SESSION.path}/:id`} element={<EditSessionScreen />} />
                    <Route path={Router.TURNBACK.path} element={<TurnbackScreen />} />
                    <Route path={Router.CREATE_TURNBACK.path} element={<CreateTurnbackScreen />} />
                    <Route path={`${Router.EDIT_TURNBACK.path}/:id`} element={<EditTurnbackScreen />} />
                    <Route path={`${Router.REPO.path}/:id`} element={<RepoScreen />} />
                    <Route path={`${Router.DETAIL_STACK.path}/:id`} element={<StackDetailScreen />} />
                    <Route path={Router.MOVE.path} element={<MoveScreen />} />
                    <Route path={Router.CREATE_MOVE.path} element={<CreateMoveScreen />} />
                    <Route path={`${Router.EDIT_MOVE.path}/:id`} element={<EditMoveScreen />} />

                    {/* Product */}
                    <Route path={Router.PRODUCT.path} element={<ProductScreen />} />
                    <Route path={Router.CREATE_PRODUCT.path} element={<CreateProductScreen />} />
                    <Route path={`${Router.EDIT_PRODUCT.path}/:id`} element={<EditProductScreen />} />
                    <Route path={Router.CATEGORY.path} element={<CategoryScreen />} />
                    <Route path={Router.PRODUCT_SUGGESTION.path} element={<ProductSuggestionScreen />} />
                    <Route path={Router.PRODUCT_COLOR.path} element={<ProductColorScreen />} />
                    <Route path={Router.PRODUCT_SIZE.path} element={<ProductSizeScreen />} />
                    <Route path={Router.ICON.path} element={<IconScreen />} />
                    <Route path={Router.POINT_RULE.path} element={<PointRuleScreen />} />
                    <Route path={Router.DISCOUNT_CAMPAIGN.path} element={<DiscountCampaignScreen />} />
                    <Route path={Router.COUPON_CATEGORY.path} element={<CouponScreen />} />
                    <Route path={Router.COLLECTION.path} element={<CollectionScreen />} />

                    {/* Account */}
                    <Route path={Router.ADMIN.path} element={<AdminScreen />} />
                    <Route path={Router.CREATE_ADMIN.path} element={<CreateAdminScreen />} />
                    <Route path={`${Router.EDIT_ADMIN.path}/:id`} element={<EditAdminScreen />} />
                    <Route path={Router.USER.path} element={<UserScreen />} />
                    <Route path={Router.CREATE_USER.path} element={<CreateUserScreen />} />
                    <Route path={`${Router.DETAIL_USER.path}/:id`} element={<DetailUserScreen />} />
                    <Route path={Router.POINT_SIGNUP.path} element={<PointSignupScreen />} />
                    <Route path={Router.INTRODUCTION_RULE.path} element={<IntroductionRuleScreen />} />
                    <Route path={Router.RANK.path} element={<RankScreen />} />
                    {/* role */}
                    <Route path={Router.ROLE.path} element={<RoleScreen />} />

                    {/* Debt */}
                    <Route path={Router.DEBT_CUSTOMER.path} element={<DebtCustomerScreen />} />
                    <Route path={`${Router.DETAIL_DEBT_CUSTOMER.path}/:id`} element={<DetailDebtCustomerScreen />} />
                    <Route path={Router.DEBT_PRODUCER.path} element={<DebtProducerScreen />} />
                    <Route path={`${Router.DETAIL_DEBT_PRODUCER.path}/:id`} element={<DetailDebtProducerScreen />} />
                    <Route path={Router.PAYMENT.path} element={<PaymentScreen />} />
                    <Route path={`${Router.DETAIL_PAYMENT.path}/:id`} element={<DetailPaymentScreen />} />

                    {/* report */}
                    <Route path={Router.STOREHOUSE_BOOK.path} element={<StorehouseBookScreen />} />
                    <Route path={Router.CHECKOUT_REPORT.path} element={<CheckoutReportScreen />} />
                    <Route path={Router.CHECKOUT_REPORT_DETAIL.path} element={<CheckoutReportDetailScreen />} />
                    <Route path={Router.UNSOLD.path} element={<UnsoldScreen />} />
                    <Route path={Router.IMPORT.path} element={<ImportScreen />} />

                    {/* messages */}
                    <Route path={Router.MESSAGES.path} element={<MessagesScreen />} />

                    {/* Setting */}
                    <Route path={Router.BANNER.path} element={<BannerScreen />} />
                    <Route path={Router.GUIDE.path} element={<GuideScreen />} />
                    <Route path={Router.FAQ.path} element={<FAQScreen />} />
                  </Route>
                  <Route path={Router.LOGIN.path} element={<LoginScreen />} />
                  <Route path={Router.PRIVACY.path} element={<Privacy />} />
                  <Route path={Router.PAGE500.path} element={<Page500Screen />} />
                  <Route path={Router.PAGE403.path} element={<Page403Screen />} />
                  <Route path="*" element={<Page404Screen />} />
                </Routes>
              </Suspense>
            </MessageProvider>
          </App>
        </ConfigAntdProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppClient;
