import { Key, ReactNode } from 'react';
import { IAdmin } from './admin.type';

export interface IBaseList<T> {
  label: string;
  value: T;
}

export interface IBasePagingRes<T> {
  data: T[];
  total: number;
  message?: string;
  status: EResponseStatus;
}

export interface IBaseProps {
  children: any;
}

export interface IPagingParams {
  limit?: number;
  page?: number;
}

export interface IParams extends IPagingParams {
  q?: string;
  init?: boolean;
}

export enum HttpStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  ERROR_SERVER = 500,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  SUCCESS = 200,
}

export interface IBaseState<T> {
  loading: boolean;
  total: number;
  data: T[];
  refresh: boolean;
  page: number;
  detail?: T;
  selected?: T[];
}

export interface IBaseConfirmModalParams {
  title?: string;
  content?: string;
  contentHtml?: string;
  onOk: () => Promise<boolean>;
}

export interface IPropsIcon {
  size?: number;
  color?: string;
}

export enum LayoutType {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum LayoutColor {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ILayout {
  type: LayoutType;
  primaryColor: string;
  bgColor: LayoutColor;
}

export interface IBreadcrumb {
  title: string;
  path: string;
}

export enum EResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface IAuth {
  expiresAt: string;
  refreshToken: string;
  token: string;
  status: EResponseStatus;
  user: IAdmin;
}

export interface IBaseEntity {
  id: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface IBaseResponse<T> {
  data?: T;
  message?: string;
  status: EResponseStatus;
}

export enum EExportType {
  SELECTED = 'SELECTED',
  ALL = 'ALL',
  PAGE = 'PAGE',
}

export enum EGenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum EModeScreen {
  DETAIL = 'DETAIL',
  EDIT = 'EDIT',
}

export interface IFlattenOptionData<T> {
  data: T;
  group?: boolean;
  groupOption?: boolean;
  key: Key;
  label?: ReactNode;
  value?: any;
}
