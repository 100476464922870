import { ConfigProvider, ThemeConfig } from 'antd';
import viVN from 'antd/locale/vi_VN';
import { memo, useMemo } from 'react';
import useLayout from 'src/stores/layout/layout.store';
import { IBaseProps, LayoutColor } from 'src/types';

function ConfigAntdProvider({ children }: IBaseProps) {
  const { layout } = useLayout();

  const theme = useMemo<ThemeConfig>(() => {
    return {
      token: {
        fontFamily: 'SVN-Gilroy',
        colorPrimary: layout.primaryColor,
      },
      components: {
        Layout: {
          headerBg: layout.bgColor === LayoutColor.DARK ? '#001529' : 'white',
        },
      },
    };
  }, [layout.primaryColor, layout.bgColor]);

  return (
    <ConfigProvider locale={viVN} theme={theme}>
      {children}
    </ConfigProvider>
  );
}

export default memo(ConfigAntdProvider);
