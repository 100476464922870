import {
  DollarOutlined,
  GiftOutlined,
  HomeOutlined,
  InboxOutlined,
  MessageOutlined,
  PieChartOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';

export interface IRoute {
  path: string;
  title: string;
  description?: string;
  permission?: string;
  icon?: any;
  children?: IRoute[];
}

export enum Route {
  LOGIN = 'LOGIN',
  HOME = 'HOME',
  PRIVACY = 'PRIVACY',
  PAGE500 = 'PAGE500',
  PAGE403 = 'PAGE403',
  PAGE404 = 'PAGE404',

  CHECKOUT_MANAGER = 'CHECKOUT_MANAGER',
  CHECKOUT = 'CHECKOUT',
  CREATE_CHECKOUT = 'CREATE_CHECKOUT',
  EDIT_CHECKOUT = 'EDIT_CHECKOUT',
  CHECKOUT_RESTORE = 'CHECKOUT_RESTORE',
  CREATE_CHECKOUT_RESTORE = 'CREATE_CHECKOUT_RESTORE',
  EDIT_CHECKOUT_RESTORE = 'EDIT_CHECKOUT_RESTORE',

  STOREHOUSE_MANAGER = 'STOREHOUSE_MANAGER',
  STOREHOUSE = 'STOREHOUSE',
  CREATE_STOREHOUSE = 'CREATE_STOREHOUSE',
  EDIT_STOREHOUSE = 'EDIT_STOREHOUSE',
  PRODUCER = 'PRODUCER',
  CREATE_PRODUCER = 'CREATE_PRODUCER',
  EDIT_PRODUCER = 'EDIT_PRODUCER',
  PRODUCTION = 'PRODUCTION',
  CREATE_PRODUCTION = 'CREATE_PRODUCTION',
  EDIT_PRODUCTION = 'EDIT_PRODUCTION',
  SESSION = 'SESSION',
  CREATE_SESSION = 'CREATE_SESSION',
  EDIT_SESSION = 'EDIT_SESSION',
  TURNBACK = 'TURNBACK',
  CREATE_TURNBACK = 'CREATE_TURNBACK',
  EDIT_TURNBACK = 'EDIT_TURNBACK',
  REPO = 'REPO',
  DETAIL_STACK = 'DETAIL_STACK',
  MOVE = 'MOVE',
  CREATE_MOVE = 'CREATE_MOVE',
  EDIT_MOVE = 'EDIT_MOVE',

  PRODUCT_MANAGER = 'PRODUCT_MANAGER',
  PRODUCT = 'PRODUCT',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  EDIT_PRODUCT = 'EDIT_PRODUCT',
  CATEGORY = 'CATEGORY',
  PRODUCT_COLOR = 'PRODUCT_COLOR',
  PRODUCT_SIZE = 'PRODUCT_SIZE',
  ICON = 'ICON',

  PROMOTION_MANAGER = 'PROMOTION_MANAGER',
  POINT_RULE = 'POINT_RULE',
  DISCOUNT_CAMPAIGN = 'DISCOUNT_CAMPAIGN',
  COUPON_CATEGORY = 'COUPON_CATEGORY',
  POINT_SIGNUP = 'POINT_SIGNUP',
  INTRODUCTION_RULE = 'INTRODUCTION_RULE',

  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  ADMIN = 'ADMIN',
  CREATE_ADMIN = 'CREATE_ADMIN',
  EDIT_ADMIN = 'EDIT_ADMIN',
  USER = 'USER',
  CREATE_USER = 'CREATE_USER',
  DETAIL_USER = 'DETAIL_USER',
  RANK = 'RANK',

  DEBT_MANAGER = 'DEBT_MANAGER',
  DEBT_CUSTOMER = 'DEBT_CUSTOMER',
  DETAIL_DEBT_CUSTOMER = 'DETAIL_DEBT_CUSTOMER',
  DEBT_PRODUCER = 'DEBT_PRODUCER',
  DETAIL_DEBT_PRODUCER = 'DETAIL_DEBT_PRODUCER',
  PAYMENT = 'PAYMENT',
  DETAIL_PAYMENT = 'DETAIL_PAYMENT',

  REPORT_MANAGER = 'REPORT_MANAGER',
  STOREHOUSE_BOOK = 'STOREHOUSE_BOOK',
  CHECKOUT_REPORT = 'CHECKOUT_REPORT',
  CHECKOUT_REPORT_DETAIL = 'CHECKOUT_REPORT_DETAIL',
  UNSOLD = 'UNSOLD',
  IMPORT = 'IMPORT',
  ROLE = 'ROLE',
  DECENTRALIZATION = 'DECENTRALIZATION',
  COLLECTION = 'COLLECTION',

  SETTING = 'SETTING',
  PRODUCT_SUGGESTION = 'PRODUCT_SUGGESTION',
  BANNER = 'BANNER',
  GUIDE = 'GUIDE',
  FAQ = 'FAQ',

  MESSAGES = 'MESSAGES',
}

export const Routes: { [key in Route]: IRoute } = {
  LOGIN: {
    path: '/dang-nhap',
    title: 'Đăng nhập',
  },
  HOME: {
    path: '/trang-chu',
    title: 'Trang chủ',
    icon: <HomeOutlined />,
  },
  PRIVACY: {
    path: '/chinh-sach',
    title: 'Chính sách',
  },
  PAGE500: {
    path: '/loi',
    title: 'Lỗi hệ thống',
  },
  PAGE403: {
    path: '/loi-phan-quyen',
    title: 'Lỗi phân quyền',
  },
  PAGE404: {
    path: '/loi-khong-tim-thay-trang',
    title: 'Lỗi không tìm thấy trang',
  },

  CHECKOUT_MANAGER: {
    path: '/checkout',
    title: 'Quản lý đơn hàng',
    icon: <ShoppingCartOutlined />,
  },
  CHECKOUT: {
    path: '/don-hang',
    title: 'Đơn hàng',
  },
  CREATE_CHECKOUT: {
    path: '/don-hang/them-moi',
    title: 'Thêm mới đơn hàng',
  },
  EDIT_CHECKOUT: {
    path: '/don-hang/chinh-sua',
    title: 'Chi tiết đơn hàng',
  },
  CHECKOUT_RESTORE: {
    path: '/hoan-hang',
    title: 'Hoàn hàng',
  },
  CREATE_CHECKOUT_RESTORE: {
    path: '/hoan-hang/them-moi',
    title: 'Thêm mới hoàn hàng',
  },
  EDIT_CHECKOUT_RESTORE: {
    path: '/hoan-hang/chinh-sua',
    title: 'Chi tiết hoàn hàng',
  },

  STOREHOUSE_MANAGER: {
    path: '/storehouse',
    title: 'Quản lý kho',
    icon: <InboxOutlined />,
  },
  STOREHOUSE: {
    path: '/kho',
    title: 'Kho',
  },
  EDIT_STOREHOUSE: {
    path: '/kho/chinh-sua',
    title: 'Chi tiết kho',
  },
  CREATE_STOREHOUSE: {
    path: '/kho/them-moi',
    title: 'Thêm mới kho',
  },
  PRODUCER: {
    path: '/nha-cung-cap',
    title: 'Nhà cung cấp',
  },
  EDIT_PRODUCER: {
    path: '/nha-cung-cap/chinh-sua',
    title: 'Chi tiết nhà cung cấp',
  },
  CREATE_PRODUCER: {
    path: '/nha-cung-cap/them-moi',
    title: 'Thêm mới nhà cung cấp',
  },
  PRODUCTION: {
    path: '/dat-hang-nhap',
    title: 'Đặt hàng nhập',
  },
  EDIT_PRODUCTION: {
    path: '/dat-hang-nhap/chinh-sua',
    title: 'Chi tiết đơn đặt hàng',
  },
  CREATE_PRODUCTION: {
    path: '/dat-hang-nhap/them-moi',
    title: 'Thêm mới đơn đặt hàng',
  },
  SESSION: {
    path: '/phien-nhap',
    title: 'Phiên nhập',
  },
  EDIT_SESSION: {
    path: '/phien-nhap/chinh-sua',
    title: 'Chi tiết phiên nhập hàng',
  },
  CREATE_SESSION: {
    path: '/phien-nhap/them-moi',
    title: 'Thêm mới phiên nhập hàng',
  },
  TURNBACK: {
    path: '/tra-hang',
    title: 'Phiên trả hàng',
  },
  EDIT_TURNBACK: {
    path: '/tra-hang/chinh-sua',
    title: 'Chi tiết phiên trả hàng',
  },
  CREATE_TURNBACK: {
    path: '/tra-hang/them-moi',
    title: 'Thêm mới phiên trả hàng',
  },
  REPO: {
    path: '/kho/vi-tri',
    title: 'Thông tin kho',
  },
  DETAIL_STACK: {
    path: '/kho/san-pham-trong-ngan',
    title: 'Sản phẩm trong ngăn',
  },
  MOVE: {
    path: '/chuyen-hang',
    title: 'Chuyển hàng',
  },
  EDIT_MOVE: {
    path: '/chuyen-hang/chinh-sua',
    title: 'Chi tiết phiên chuyển hàng',
  },
  CREATE_MOVE: {
    path: '/chuyen-hang/them-moi',
    title: 'Thêm mới phiên chuyển hàng',
  },

  PRODUCT_MANAGER: {
    path: '/product',
    title: 'Quản lý sản phẩm',
    icon: <ShopOutlined />,
  },
  PRODUCT: {
    path: '/san-pham',
    title: 'Sản phẩm',
  },
  CREATE_PRODUCT: {
    path: '/san-pham/them-moi',
    title: 'Thêm mới sản phẩm',
  },
  EDIT_PRODUCT: {
    path: '/san-pham/chinh-sua',
    title: 'Chi tiết',
  },
  CATEGORY: {
    path: '/danh-muc',
    title: 'Danh mục',
  },
  PRODUCT_COLOR: {
    path: '/mau-sac',
    title: 'Màu sắc',
  },
  PRODUCT_SIZE: {
    path: '/kick-thuoc',
    title: 'Kích thước',
  },
  ICON: {
    path: '/icon',
    title: 'Icon',
  },
  COLLECTION: {
    path: '/bo-suu-tap',
    title: 'Bộ sưu tập',
  },

  PROMOTION_MANAGER: {
    path: '/promotion',
    title: 'Quản lý ưu đãi',
    icon: <GiftOutlined />,
  },
  POINT_RULE: {
    path: '/luat-diem',
    title: 'Luật điểm',
  },
  COUPON_CATEGORY: {
    path: '/coupon-category',
    title: 'Coupon',
  },
  DISCOUNT_CAMPAIGN: {
    path: '/chuong-trinh-sale',
    title: 'Chương trình sale',
  },
  POINT_SIGNUP: {
    path: '/diem-cong-dang-ky',
    title: 'Điểm cộng đăng ký',
  },
  INTRODUCTION_RULE: {
    path: '/uu-dai-gioi-thieu',
    title: 'Ưu đãi giới thiệu',
  },

  ACCOUNT_MANAGER: {
    path: '/account',
    title: 'Quản lý tài khoản',
    icon: <UserOutlined />,
  },
  ADMIN: {
    path: '/nhan-vien',
    title: 'Nhân viên',
  },
  CREATE_ADMIN: {
    path: '/nhan-vien/them-moi',
    title: 'Thêm mới nhân viên',
  },
  EDIT_ADMIN: {
    path: '/nhan-vien/chinh-sua',
    title: 'Chi tiết nhân viên',
  },
  USER: {
    path: '/khach-hang',
    title: 'Khách hàng',
  },
  CREATE_USER: {
    path: '/khach-hang/them-moi',
    title: 'Thêm mới khách hàng',
  },
  DETAIL_USER: {
    path: '/khach-hang/chi-tiet',
    title: 'Thông tin khách hàng',
  },
  RANK: {
    path: '/hang',
    title: 'Hạng',
  },

  DEBT_MANAGER: {
    path: '/debt',
    title: 'Quản lý công nợ',
    icon: <DollarOutlined />,
  },
  DEBT_CUSTOMER: {
    path: '/cong-no-khach-hang',
    title: 'Công nợ khách hàng',
  },
  DEBT_PRODUCER: {
    path: '/cong-no-nha-san-xuat',
    title: 'Công nợ nhà sản xuất',
  },
  DETAIL_DEBT_CUSTOMER: {
    path: '/cong-no-khach-hang/chi-tiet',
    title: 'Chi tiết công nợ khách hàng',
  },
  DETAIL_DEBT_PRODUCER: {
    path: '/cong-no-nha-san-xuat/chi-tiet',
    title: 'Chi tiết công nợ nhà sản xuất',
  },
  PAYMENT: {
    path: '/thanh-toan',
    title: 'Phiếu thu/chi',
  },
  DETAIL_PAYMENT: {
    path: '/thanh-toan/chi-tiet',
    title: 'Chi tiết phiếu thu/chi',
  },

  REPORT_MANAGER: {
    path: '/report',
    title: 'Báo cáo',
    icon: <PieChartOutlined />,
  },
  STOREHOUSE_BOOK: {
    path: '/bao-cao-so-kho',
    title: 'Sổ kho',
  },
  CHECKOUT_REPORT: {
    path: '/bao-cao-ban-hang',
    title: 'Bán hàng',
  },
  CHECKOUT_REPORT_DETAIL: {
    path: '/bao-cao-don-hang',
    title: 'Đơn hàng',
  },
  UNSOLD: {
    path: '/bao-cao-ton-kho',
    title: 'Tồn kho',
  },
  IMPORT: {
    path: '/bao-cao-nhap-hang',
    title: 'Nhập hàng',
  },

  SETTING: {
    path: '/cai-dat',
    title: 'Cài đặt',
    icon: <SettingOutlined />,
  },
  PRODUCT_SUGGESTION: {
    path: '/san-pham-goi-y',
    title: 'Sản phẩm gợi ý',
  },
  BANNER: {
    path: '/banner',
    title: 'Banner',
  },
  ROLE: {
    path: 'vai-tro',
    title: 'Vai trò',
  },
  DECENTRALIZATION: {
    path: 'phan-quyen',
    title: 'Phân quyền',
    icon: <UserOutlined />,
  },
  GUIDE: {
    path: '/huong-dan-su-dung',
    title: 'Hướng dẫn sử dụng',
  },
  FAQ: {
    path: '/cau-hoi-thuong-gap',
    title: 'Câu hỏi thường gặp',
  },
  MESSAGES: {
    path: '/messages',
    title: 'Tin nhắn trực tiếp',
    icon: <MessageOutlined />,
  },
};
