import { createContext, memo, useContext, useEffect } from 'react';
import io from 'socket.io-client';
import { IBaseProps } from 'src/types';

const hostSocket = process.env.REACT_APP_API_DOMAIN || 'https://api.truyenthanhviet.com/';

const webSocket = io(hostSocket, {
  withCredentials: true,
  transports: ['websocket'],
  // host: process.env.REACT_APP_HOST_SOCKET,
});

const SocketContext = createContext(webSocket);

export const useSocket = () => useContext(SocketContext);

function SocketProvider({ children }: IBaseProps) {
  useEffect(() => {
    // if (webSocket) {
    //   webSocket.on(SOCKET_EVENTS.CONVERT_TEXT_TO_SPEECH_MESSAGE, (payload: any) => {
    //     console.log(payload, 'payload');
    //   });
    // }
    webSocket.connect();

    return () => {
      if (webSocket) {
        webSocket.disconnect();
      }
    };
  }, []);

  return <SocketContext.Provider value={webSocket}>{children}</SocketContext.Provider>;
}

export default memo(SocketProvider);
