import dayjs from 'dayjs';
import { IAdmin } from './admin.type';
import { ECheckoutStatus, ICheckout } from './checkout.type';
import { IBaseEntity, IParams } from './common.type';
import { IOrderRestore, IOrderRestoreBody } from './order_restore.type';
import { EPaymentMethod, EPaymentStatus } from './payment.type';
import { IPaymentDetail } from './payment_detail.type';

export interface ICheckoutRestore extends IBaseEntity {
  code: string;
  status: ECheckoutRestoreStatus;
  checkout_id: number;
  checkout: ICheckout;
  admin_id: number;
  admin: IAdmin;
  orderRestores: IOrderRestore[];
  paymentDetails: IPaymentDetail[];
  pay_status: EPaymentStatus;
  pay_money: number;
}

export interface ICheckoutRestoreQuery extends IParams {
  code?: string;
  checkout_id?: number;
  storehouse_id?: number;
  status?: ECheckoutStatus;
  restore_status?: ECheckoutRestoreStatus;
  pay_status?: EPaymentStatus;
  start?: string;
  end?: string;
  dateRange?: dayjs.Dayjs[];
}
export interface ICheckoutRestoreBody {
  orderRestores: IOrderRestoreBody[];
}

export interface ICreateCheckoutRestoreBody extends ICheckoutRestoreBody {}
export interface IUpdateCheckoutRestoreBody extends ICheckoutRestoreBody {}

export enum ECheckoutRestoreStatus {
  PENDING = 'PENDING',
  RESTORED = 'RESTORED',
}

export interface ICheckoutRestorePayment {
  pay_money: number;
  method: EPaymentMethod;
}
